<template>
  <div id="modalSelectDateSchedule" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Selecionar a Clínica Parceira e Data/Hora</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="updateSchedule">
          <div class="modal-body pb-1">
            <LoadingData v-if="loadData" />
            <div v-else>
              <h6 class="text-center">Selecione a clínica parceira</h6>
              <input
                type="text"
                class="form-control mb-3"
                v-model="termSearch"
                placeholder="Digite o nome da clínica para filtrar"
              />
              <div class="row">
                <div class="col-12" id="container-parther">
                  <div class="row">
                    <div
                      v-for="provider in servicesProvidersFiltered"
                      :key="provider.codigoPrestador"
                      class="d-flex col-lg-12"
                      @click="setProvider(provider)"
                    >
                      <div
                        class="card card-body cursor-pointer p-1 mb-1"
                        :class="
                          provider.codigoPrestador ==
                          selectedProvider.codigoPrestador
                            ? 'bg-success text-white'
                            : ''
                        "
                      >
                        <p class="mb-0">
                          <strong>{{ provider.nomePrestador }}</strong> <br />
                          Endereço: {{ provider.endereco }},
                          {{ provider.numeroEndereco }} - {{ provider.bairro }},
                          {{ provider.estado }}/{{ provider.cidade }} | Tel:
                          {{ provider.telefone }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h6 class="text-center">
                Selecione uma das opções sugeridas de data/hora
              </h6>
              <div class="row">
                <div
                  v-for="dataSuggestion in dataSuggestions"
                  :key="dataSuggestion.id"
                  class="d-flex col-lg-4"
                  @click="setDate(dataSuggestion)"
                >
                  <div
                    class="card card-body justify-content-center p-2 cursor-pointer"
                    :class="
                      dataSuggestion.id == selectedDateTime.id
                        ? 'bg-success text-white'
                        : ''
                    "
                  >
                    <p class="mb-0 text-center">
                      {{
                        dateTimeSqlToDateTimeBR(
                          `${dataSuggestion.date} ${dataSuggestion.time}`,
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div class="d-flex col-lg-4">
                  <div class="card card-body p-2">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark"
                      title="Adicionar nova data/hora"
                      :disabled="showAddNewDateTime"
                      @click="addNewDateTime()"
                    >
                      Adicionar nova data/hora
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="showAddNewDateTime" class="card card-body mb-0">
                <p class="text-center">Adicionar nova data/hora</p>
                <div class="row g-3">
                  <div class="col-12 col-md-6">
                    <div>
                      <label class="form-label">Data:</label>
                      <input
                        type="date"
                        class="form-control"
                        :min="tomorrow()"
                        v-model="selectedDateTime.date"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <label class="form-label">Hora:</label>
                    <input
                      type="time"
                      class="form-control"
                      v-model="selectedDateTime.time"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link text-secondary"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-secondary"
              title="Confirmar"
              :disabled="disabledButton"
            >
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import { tomorrow, dateTimeSqlToDateTimeBR } from '@/utils/date'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'SelectDateScheduleModal',

  props: {
    uuid: {
      type: String,
      required: true,
    },
  },

  components: {
    LoadingData,
  },

  data() {
    return {
      loadData: true,
      disabledButton: true,
      showAddNewDateTime: false,
      schedule: {},
      servicesProviders: [],
      dataSuggestions: [],
      termSearch: '',
      servicesProvidersFiltered: [],
      selectedProvider: {
        codigoPrestador: null,
      },
      selectedDateTime: {
        id: null,
        date: null,
        time: null,
      },
      tomorrow,
      dateTimeSqlToDateTimeBR,
    }
  },

  watch: {
    termSearch: {
      handler(newVal, oldVal) {
        if (newVal.length === 0) {
          this.servicesProvidersFiltered = this.servicesProviders
        } else {
          this.servicesProvidersFiltered = this.servicesProviders.filter(
            (provider) =>
              provider.nomePrestador
                .toLowerCase()
                .includes(newVal.toLowerCase()) ||
              provider.cidade.toLowerCase().includes(newVal.toLowerCase()),
          )
        }
      },
      immediate: true,
    },
    selectedProvider: {
      handler(newVal, oldVal) {
        if (
          newVal.codigoPrestador &&
          this.selectedDateTime.date &&
          this.selectedDateTime.time
        ) {
          this.disabledButton = false
        }
      },
      immediate: true,
    },
    selectedDateTime: {
      handler(newVal, oldVal) {
        if (
          this.selectedProvider.codigoPrestador &&
          newVal.date &&
          newVal.time
        ) {
          this.disabledButton = false
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async getSchedule() {
      try {
        const response = await this.$store.dispatch('getSchedule', {
          uuid: this.uuid,
        })
        this.schedule = response.schedule
        this.dataSuggestions = response.dataSuggestion

        this.getServicesProviders()
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getServicesProviders() {
      try {
        this.servicesProviders = await this.$store.dispatch(
          'getServicesProviders',
        )
        this.servicesProvidersFiltered = this.servicesProviders
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    addNewDateTime() {
      this.selectedDateTime = {
        id: null,
        date: null,
        time: null,
      }

      this.showAddNewDateTime = true
      this.disabledButton = true
    },

    setProvider(provider) {
      this.selectedProvider = provider
    },

    setDate(dataSuggestion) {
      this.showAddNewDateTime = false
      this.selectedDateTime = dataSuggestion
    },

    async updateSchedule() {
      this.disabledButton = true
      try {
        const response = await this.$store.dispatch('selectDateSchedule', {
          uuid: this.uuid,
          form: {
            selectedProvider: this.selectedProvider,
            selectedDateTime: this.selectedDateTime,
          },
        })

        hideModal()
        this.$emit('closeModal', true)
        this.$root.$refs.notification.success(response.message)
      } catch (error) {
        this.disabledButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    showModal('modalSelectDateSchedule')
    this.getSchedule()
  },
}
</script>

<style scoped>
#container-parther {
  height: 250px;
  overflow-y: scroll;
}
</style>
