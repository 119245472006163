let popover = null

const showPopover = (item) => {
  if (popover) {
    popover.dispose()
  }

  popover = new window.bootstrap.Popover(document.getElementById(item.id), {
    title: item.title,
    content: item.content,
    placement: 'top',
  })
  popover.show()
}

const hidePopover = () => {
  if (popover) {
    popover.dispose()
    popover = null
  }
}

export { showPopover, hidePopover }
