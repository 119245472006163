<template>
  <div class="content">
    <div class="card">
      <div class="card-header d-flex align-items-center py-0">
        <h4 class="text-secondary py-3 mb-0">Agendamentos</h4>
        <div class="ms-auto">
          <router-link
            to="/accreditations/home"
            class="btn btn-outline-dark"
            title="Voltar Para à Tela Inicial"
          >
            Voltar Para à Tela Inicial
          </router-link>
        </div>
      </div>
      <div class="card-body mb-2">
        <form @submit.prevent="getSchedules">
          <div class="row g-3">
            <div class="col-md-5">
              <label class="form-label">
                Data Inicial: <span class="text-danger">*</span>
              </label>
              <input
                type="date"
                class="form-control"
                placeholder="00/00/0000"
                v-model="startDate"
              />
            </div>
            <div class="col-md-5">
              <label class="form-label">
                Data Final: <span class="text-danger">*</span>
              </label>
              <input
                type="date"
                class="form-control"
                placeholder="00/00/0000"
                v-model="endDate"
              />
            </div>
            <div class="col-md-2">
              <button
                type="submit"
                class="btn btn-secondary mt-md-4 mt-2 w-100"
                title="Buscar"
                :disabled="loadData"
              >
                Buscar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <LoadingData v-if="loadData" />
    <SchedulesTable v-if="schedules.length > 0" :schedules="schedules" />
    <div v-if="!loadData && schedules.length == 0">
      <h4 class="text-secondary text-center">
        Nenhum agendamento encontrado para o período selecionado
      </h4>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import SchedulesTable from '@/components/accreditations/schedules/list/SchedulesTable.vue'
import { getFirstDayOfMonth, getLastDayOfMonth } from '@/utils/date'

export default {
  name: 'ListSchedule',

  components: {
    LoadingData,
    SchedulesTable,
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      schedules: [],
      loadData: false,
    }
  },

  methods: {
    async getSchedules() {
      try {
        this.loadData = true
        this.schedules = await this.$store.dispatch('getSchedules', {
          startDate: this.startDate,
          endDate: this.endDate,
        })
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    setInitialFilters() {
      const year = new Date().getFullYear()
      const month = new Date().getMonth() + 1
      this.startDate = getFirstDayOfMonth(year, month)
      this.endDate = getLastDayOfMonth(year, month)
      this.getSchedules()
    },
  },

  mounted() {
    this.setInitialFilters()
  },
}
</script>
