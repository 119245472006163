<template>
  <div id="modalFinishSchedule" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Finalizar Agendamento</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="finishSchedule">
          <div class="modal-body pb-1">
            <div class="col-12">
              <h6 class="text-center">Selecione uma opção:</h6>
              <div class="row">
                <div class="col-4">
                  <div class="w-100">
                    <input
                      type="radio"
                      class="btn-check"
                      :id="`option_finish_${calendarStatusEnum.COMPARECEU}`"
                      name="selectedOption"
                      :value="calendarStatusEnum.COMPARECEU"
                      v-model="selectedOption"
                    />
                    <label
                      class="btn my-1 w-100"
                      :class="
                        selectedOption == calendarStatusEnum.COMPARECEU
                          ? 'btn-success'
                          : 'btn-light'
                      "
                      :for="`option_finish_${calendarStatusEnum.COMPARECEU}`"
                    >
                      Compareceu
                    </label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="w-100">
                    <input
                      type="radio"
                      class="btn-check"
                      :id="`option_finish_${calendarStatusEnum.NAO_COMPARECEU}`"
                      name="selectedOption"
                      :value="calendarStatusEnum.NAO_COMPARECEU"
                      v-model="selectedOption"
                    />
                    <label
                      class="btn my-1 w-100"
                      :class="
                        selectedOption == calendarStatusEnum.NAO_COMPARECEU
                          ? 'btn-success'
                          : 'btn-light'
                      "
                      :for="`option_finish_${calendarStatusEnum.NAO_COMPARECEU}`"
                    >
                      Não Compareceu
                    </label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="w-100">
                    <input
                      type="radio"
                      class="btn-check"
                      :id="`option_finish_${calendarStatusEnum.CANCELADO}`"
                      name="selectedOption"
                      :value="calendarStatusEnum.CANCELADO"
                      v-model="selectedOption"
                    />
                    <label
                      class="btn my-1 w-100"
                      :class="
                        selectedOption == calendarStatusEnum.CANCELADO
                          ? 'btn-success'
                          : 'btn-light'
                      "
                      :for="`option_finish_${calendarStatusEnum.CANCELADO}`"
                    >
                      Cancelado
                    </label>
                  </div>
                </div>
                <div v-if="showCancelReason" class="col-12 mt-3">
                  <p>
                    Motivo do cancelamento: <span class="text-danger">*</span>
                  </p>
                  <textarea
                    class="form-control"
                    placeholder="Motivo do cancelamento"
                    rows="3"
                    v-model="canceledReason"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link text-secondary"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-secondary"
              title="Finalizar"
              :disabled="disabledButton"
            >
              Finalizar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import calendarStatusEnum from '@/enum/calendarStatusEnum'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'FinishScheduleModal',

  props: {
    uuid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      disabledButton: true,
      selectedOption: '',
      showCancelReason: false,
      canceledReason: '',
      calendarStatusEnum,
    }
  },

  watch: {
    selectedOption: {
      handler(newVal, oldVal) {
        if (newVal === calendarStatusEnum.CANCELADO) {
          this.showCancelReason = true
          this.disabledButton = true
        } else {
          this.showCancelReason = false
          this.canceledReason = ''
          this.disabledButton = false
        }
      },
      immediate: true,
    },
    canceledReason: {
      handler(newVal, oldVal) {
        if (newVal.length > 5) {
          this.disabledButton = false
        } else {
          this.disabledButton = true
        }
      },
      immediate: true,
    },
  },

  methods: {
    async finishSchedule() {
      this.disabledButton = true
      try {
        const response = await this.$store.dispatch('finishSchedule', {
          uuid: this.uuid,
          status: this.selectedOption,
          canceledReason: this.canceledReason,
        })

        hideModal()
        this.$emit('closeModal', true)
        this.$root.$refs.notification.success(response.message)
      } catch (error) {
        this.disabledButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    showModal('modalFinishSchedule')
  },
}
</script>
